import { SicAlertOccurrence } from '../services/cyber-api';
import { ISicTraffic } from '../models/sic-traffic';

export class SicUtilities {
    public static extractPattern(pattern: string): string {
        if (!pattern) return null;

        // Replace leading bracket
        const firstPatternChar = pattern[0];
        if (firstPatternChar === '[')
            pattern = pattern.substring(1, pattern.length);

        // Replace trailing bracket
        const lastPatternChar = pattern.substring(pattern.length - 1, pattern.length);
        if (lastPatternChar === ']')
            pattern = pattern.substring(0, pattern.length - 1);

        return pattern;
    }

    public static extractTraffic(sicAlertOccurrences: SicAlertOccurrence[]): ISicTraffic | null {
        if (!sicAlertOccurrences || sicAlertOccurrences.length === 0) return null;

        let domains: string[] = [];
        let urls: string[] = [];
        let destinationIps: string[] = [];
        let sourceIps: string[] = [];
        const destinationBytes: number[] = [];
        const destinationPackets: number[] = [];
        const sourceBytes: number[] = [];
        const sourcePackets: number[] = [];

        for (const occurrence of sicAlertOccurrences) {
            domains.push(occurrence.url?.domain);
            urls.push(occurrence.url?.original);
            destinationIps.push(occurrence.destination?.ip);
            sourceIps.push(occurrence.source?.ip);
            destinationBytes.push(occurrence.destination?.bytes);
            destinationPackets.push(occurrence.destination?.packets);
            sourceBytes.push(occurrence.source?.bytes);
            sourcePackets.push(occurrence.source?.packets);
        }

        domains = [...new Set(domains)].filter((x) => x && x !== 'null');
        urls = [...new Set(urls)].filter((x) => x && x !== 'null');
        destinationIps = [...new Set(destinationIps)];
        sourceIps = [...new Set(sourceIps)];

        // sort the urls, make sure single slash is at the end
        urls = urls.sort((a, b) => {
            if (a === b) return 0;
            if (a === '/') return 1;
            if (b === '/') return -1;
            return a.localeCompare(b);
        });

        if (domains.length > 1) console.warn('Multiple unique domains found in occurrences, this is unexpected.');
        if (urls.length > 1) console.warn('Multiple unique urls found in occurrences, this is unexpected.');
        if (destinationIps.length > 1) console.warn('Multiple unique destination ips found in occurrences, this is unexpected.');
        if (sourceIps.length > 1) console.warn('Multiple unique source ips found in occurrences, this is unexpected.');

        return {
            domain: domains.length > 0 ? domains[0] : null,
            urls: urls.length > 0 ? urls : null,
            destinationIp: destinationIps.length > 0 ? destinationIps[0] : null,
            sourceIp: sourceIps.length > 0 ? sourceIps[0] : null,
            destinationBytes: destinationBytes.length > 0 ? destinationBytes.reduce((acc, next) => acc + next, 0) : null,
            destinationPackets: destinationPackets.length > 0 ? destinationPackets.reduce((acc, next) => acc + next, 0) : null,
            sourceBytes: sourceBytes.length > 0 ? sourceBytes.reduce((acc, next) => acc + next, 0) : null,
            sourcePackets: sourcePackets.length > 0 ? sourcePackets.reduce((acc, next) => acc + next, 0) : null
        };
    }

    public static extractDestinationBytes(sicAlertOccurrences: SicAlertOccurrence[]): number | null {
        if (!sicAlertOccurrences || sicAlertOccurrences.length === 0) return null;

        return sicAlertOccurrences.reduce((byteTotals: number | null, so): number => {
            if (so.destination.bytes && byteTotals === null) byteTotals = 0;

            byteTotals += so.destination.bytes;
            return byteTotals;
        }, null);
    }

    public static extractSourceBytes(sicAlertOccurrences: SicAlertOccurrence[]): number | null {
        if (!sicAlertOccurrences || sicAlertOccurrences.length === 0) return null;

        return sicAlertOccurrences.reduce((byteTotals: number | null, so): number => {
            if (so.source.bytes && byteTotals === null) byteTotals = 0;

            byteTotals += so.source.bytes;
            return byteTotals;
        }, null);
    }

    public static extractExternalIpv4(occurrences: SicAlertOccurrence[]): string | null {
        if (!occurrences || occurrences.length === 0) return null;

        const ips: string[] = [];
        for (const occurrence of occurrences) {
            const ip = occurrence.destination?.ip;
            ips.push(ip);
        }

        return ips.length > 0 ? ips[0] : null;
    }

    public static extractLocalIpv4(occurrences: SicAlertOccurrence[]): string | null {
        if (!occurrences || occurrences.length === 0) return null;

        const ips: string[] = [];
        for (const occurrence of occurrences) {
            const ip = occurrence.source?.ip;
            ips.push(ip);
        }

        return ips.length > 0 ? ips[0] : null;
    }
}
