import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { inlineView } from 'aurelia-templating';

@inlineView('<template><router-view swap-order="after"></router-view></template>')
export class VulnerabilitiesIndex {
    private configureRouter(config: RouterConfiguration, router: Router): void {
        config.map([
            { route: '', name: 'vulnerabilities', moduleId: PLATFORM.moduleName('./vulnerabilities') },
            { route: ':id', href: 'vulnerability', name: 'vulnerability', moduleId: PLATFORM.moduleName('./vulnerability/vulnerability'), nav: true, title: 'Vulnerability Details', settings: { icon: 'fal fa-shield-exclamation', breadcrumb: true } }
        ]);
    }
}
