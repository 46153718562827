import {
    IGetSoftwareStatsResponseItem,
    OrderingType, QualysAsset, QualysAssetsApiClient,
    QualysSoftwareApiClient, QualysVessel,
    QualysVesselsApiClient
} from '../../../services/cyber-api';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { ISortModel } from '../components/sort/sort';
import { StateApi } from '../../../services/state-api';
import { ICollapsible } from '../../../models/icollapsible';
import { ThemeColors } from '../../../resources/theme/theme-colors';
import { Router } from 'aurelia-router';
import { FilterNames } from '../models/filter-names';
import { UrlUtilities } from '../../../utilities/url-utilities';

@autoinject()
export class Software {
    public query?: string;
    public software: ICollapsibleSoftware[] = undefined;
    public totalInstallationCount: number = undefined;
    public loading: boolean = false;
    private FilterNames: typeof FilterNames = FilterNames;
    private vessels: QualysVessel[] = undefined;
    private assets: QualysAsset[] = undefined;
    private filters = {
        siteId: undefined,
        asset: undefined,
    };
    private filterDefaults = {
        siteId: undefined,
        asset: undefined,
    };
    private series: Highcharts.SeriesOptionsType[];
    private options: Highcharts.Options = {
        chart: {
            zooming: {
                type: null
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            pie: {
                size: '100%',
                innerSize: '80%',
                dataLabels: {
                    enabled: false
                }
            }
        }
    };

    @observable() public sortModel: ISortModel;

    constructor(
        private qualysSoftwareApiClient: QualysSoftwareApiClient,
        private qualysVesselsApiClient: QualysVesselsApiClient,
        private qualysAssetsApiClient: QualysAssetsApiClient,
        private state: StateApi,
        private router: Router
    ) {
    }

    private async activate(params: any): Promise<void> {
        this.query = params.query;

        this.setFiltersFromParams(params);

        this.sortModel = {
            direction: 'desc',
            field: 'InstallationCount',
        };

        this.retrieveFilters();
    }

    private setFiltersFromParams(params: any): void {
        this.filters.siteId = params.site ? params.site : this.filterDefaults.siteId;
        this.filters.asset = params.asset ? params.asset : this.filterDefaults.asset;
    }

    private retrieveFilters(): void {
        // To prioritize initial loading speed, delay retrieval of entities necessary for filters
        setTimeout(() => {
            this.retrieveVessels();
            this.retrieveAssets();
        }, 500);
    }

    private async retrieveVessels(): Promise<void> {
        const vesselsPagedList = await this.qualysVesselsApiClient.getQualysVessels(
            this.state.company(),       // company
            undefined,                  // query
            undefined,                  // fromHighestRisk
            undefined,                  // toHighestRisk
            undefined,                  // fromAverageRisk
            undefined,                  // toAverageRisk
            'SiteName',                 // sortField
            'asc',                      // sortDirection
            OrderingType.AlphaNumeric,  // sortType
        );
        this.vessels = vesselsPagedList.items;
    }

    private async retrieveAssets(): Promise<void> {
        const assetsPagedList = await this.qualysAssetsApiClient.getQualysAssets(
            this.state.company(),       // company
            0,                          // skip
            5000,                       // take
            undefined,                  // query
            undefined,                  // site id
            undefined,                  // fromRisk
            undefined,                  // toRisk
            undefined,                  // operatingSystem
            undefined,                  // software
            undefined,                  // externalReferences
            'Name',                     // sortField
            'desc',                     // sortDirection
            OrderingType.AlphaNumeric,  // orderingType
        );
        // Sort by asset name so that any names that are actually IPs are sorted to the bottom of the list
        assetsPagedList.items.sort((a, b) => {
            const ipRegex = /((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}/;
            const aIsIp = a.name.match(ipRegex);
            const bIsIp = b.name.match(ipRegex);
            if (aIsIp && !bIsIp)
                return 1;
            else if (!aIsIp && bIsIp)
                return -1;
            else
                return a.name.localeCompare(b.name);
        });
        this.assets = assetsPagedList.items;
    }

    private configureChartOptions(): void {
        if (this.software.length > 0) {
            // Set dynamic options, such as title + subtitle
            // Add subtitle if it's defined
            this.options = {
                ...this.options,
                subtitle: {
                    text: 'Top 5 </br> Installed',
                    verticalAlign: 'middle',
                    floating: true,
                    style: {
                        color: ThemeColors.DarkerGrey,
                        fontSize: '12pt'
                    },
                    y: 15
                },
            };
            this.series = this.mapSeries();
        } else {
            this.options = {
                ...this.options,
                subtitle: null,
            };
            this.series = [];
        }
    }

    private mapSeries(): Highcharts.SeriesOptionsType[] {
        const chartColors = [ThemeColors.Red, ThemeColors.Blue, ThemeColors.Green, ThemeColors.Orange, ThemeColors.Yellow];
        // Get the top 4 software and calculate a grey one for the rest
        // Sorting the software by installation count is done on a new array to prevent the software array from being
        // sorted, as we want the actual sorting to be done by the API. The relatively new array function toSorted() is
        // now available in JS, but isn't yet used here for compatibility reasons.
        const topFour = [...this.software].sort((a, b) => b.installationCount - a.installationCount).slice(0, 4);
        const rest = this.totalInstallationCount - topFour.reduce((acc, curr) => acc + curr.installationCount, 0);

        const data = topFour.map((s: ICollapsibleSoftware, index) => {
            return {
                color: chartColors[index],
                name: s.name,
                y: s.installationCount / this.totalInstallationCount * 100
            };
        });
        if (rest > 0)
            data.push({
                color: ThemeColors.Grey,
                name: 'Other',
                y: rest / this.totalInstallationCount * 100
            });
        return [
            {
                name: 'Software',
                data: data,
                point: {
                    events: {
                        click: (event: any): boolean => {
                            // Retrieve the name from the slice name
                            const sliceName: string = event.point.name;

                            this.router.navigateToRoute('vulnerability-scanner/assets', { software: sliceName });

                            return true;
                        }
                    }
                },
                cursor: 'pointer',
                type: 'pie'
            }
        ];
    }

    private async search(searchValue: string): Promise<void> {
        this.loading = true;
        this.query = searchValue;

        this.software = await this.qualysSoftwareApiClient.getSoftwareStats(
            this.state.company(),      // company
            this.query,                // query
            this.filters.siteId,       // siteId
            this.filters.asset,        // hostExternalReference
            this.sortModel.field,      // sortField
            this.sortModel.direction,  // sortDirection
            OrderingType.AlphaNumeric, // orderingType
        ).then((software) => this.mapToCollapsible(software));

        if (this.software.length > 0)
            this.totalInstallationCount = this.software.reduce((acc, curr) => acc + curr.installationCount, 0);
        else
            this.totalInstallationCount = undefined;

        this.configureChartOptions();

        this.loading = false;
    }

    private async sortModelChanged(): Promise<void> {
        await this.search(this.query);
    }

    private filterChanged(): void {
        this.setUrlFilterParams();

        this.search(this.query);
    }

    private clearFilter(filter: FilterNames): void {
        switch (filter) {
            case FilterNames.SiteId:
                this.filters.siteId = this.filterDefaults.siteId;
                break;
            case FilterNames.Asset:
                this.filters.asset = this.filterDefaults.asset;
                break;
        }

        this.search(this.query);

        this.setUrlFilterParams();
    }

    private clearFilters(): void {
        this.filters.siteId = this.filterDefaults.siteId;
        this.filters.asset = this.filterDefaults.asset;

        this.search(this.query);

        this.setUrlFilterParams();
    }

    @computedFrom('filters.siteId', 'filters.asset')
    private get hasActiveFilters(): boolean {
        return this.filters.siteId
            || this.filters.asset;
    }

    private setUrlFilterParams(): void {
        const route = 'vulnerability-scanner/software';

        const params = new URLSearchParams();

        if (this.filters.siteId)
            params.append('site', this.filters.siteId);

        if (this.filters.asset)
            params.append('asset', this.filters.asset);

        UrlUtilities.setUrlParams(this.router, route, params);
    }

    private mapToCollapsible(software: IGetSoftwareStatsResponseItem[]): ICollapsibleSoftware[] {
        return software.map((softwareItem) => {
            return {
                ...softwareItem,
                collapsed: true
            };
        });
    }
}

interface ICollapsibleSoftware extends ICollapsible, IGetSoftwareStatsResponseItem {
}
