import { bindable } from 'aurelia-templating';

export class SeverityDistribution {
    @bindable() public severities: { [key: string]: number; };
    @bindable() public siteId: string;
    private totalCount: number;

    public attached(): void {
        this.totalCount = 0;
        for (const severity in this.severities)
            if (this.severities[severity])
                this.totalCount += this.severities[severity];
    }

    public getWidthPercentage(severityCount: number): number {
        return severityCount / this.totalCount * 100;
    }
}
