import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { StateApi } from '../../../services/state-api';
import {
    OrderingType,
    QualysAsset,
    QualysAssetsApiClient,
    QualysAssetStatsResult,
    QualysSoftwareApiClient,
    QualysVessel,
    QualysVesselsApiClient,
} from '../../../services/cyber-api';
import { ISortModel } from '../components/sort/sort';
import { ThemeColors } from '../../../resources/theme/theme-colors';
import { MultiRangeSlider } from '../../../components/multi-range-slider/multi-range-slider';
import { FilterNames } from '../models/filter-names';
import { UrlUtilities } from '../../../utilities/url-utilities';
import { Router } from 'aurelia-router';

@autoinject()
export class Assets {
    public query?: string;
    private assets: QualysAsset[] = undefined;
    private total: number;
    private take: number = 50;
    private skip: number = 0;
    private assetsLoading: boolean = false;
    private series: Highcharts.SeriesOptionsType[];
    private highestRisk: number | undefined = undefined;
    private averageRisk: number | undefined = undefined;
    private affectedAssets: number | undefined = undefined;
    private totalAssets: number | undefined = undefined;
    private FilterNames: typeof FilterNames = FilterNames;
    private riskFilterRangeSlider: MultiRangeSlider;
    private operatingSystems: string[] = [
        'Windows',
        'Linux',
        'Network',
        'Other'
    ];
    private softwareNames: string[] = [];
    private vessels: QualysVessel[] = undefined;
    private filters = {
        siteId: undefined,
        riskRange: [0, 100],
        operatingSystem: undefined,
        software: undefined,
    };
    private filterDefaults = {
        siteId: undefined,
        riskRange: [0, 100],
        operatingSystem: undefined,
        software: undefined,
    };
    private options: Highcharts.Options = {
        chart: {
            zooming: {
                type: null
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            pie: {
                size: '100%',
                innerSize: '80%',
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    pointFormat: '{point.name}: <b>{point.y}</b>'
                }
            }
        }
    };

    @observable() public sortModel: ISortModel;

    constructor(
        private qualysAssetsApiClient: QualysAssetsApiClient,
        private qualysVesselsApiClient: QualysVesselsApiClient,
        private qualysSoftwareApiClient: QualysSoftwareApiClient,
        private state: StateApi,
        private router: Router,
    ) {
    }

    private async activate(params: any): Promise<void> {
        this.query = params.query;

        this.setFiltersFromParams(params);

        this.sortModel = {
            direction: 'desc',
            field: 'LastVmScannedDate',
        };

        this.retrieveStats();
        this.retrieveFilters();
    }

    private setFiltersFromParams(params: any): void {
        this.filters.siteId = params.site ? params.site : this.filterDefaults.siteId;
        this.filters.riskRange = params.risk ? params.risk.split(',').map(Number) : this.filterDefaults.riskRange;
        this.filters.operatingSystem = params.os ? params.os : this.filterDefaults.operatingSystem;
        this.filters.software = params.software ? params.software : this.filterDefaults.software;
    }

    private async retrieveFilters(): Promise<void> {
        // To prioritize initial loading speed, delay retrieval of entities necessary for filters
        setTimeout(() => {
            this.qualysSoftwareApiClient.getSoftwareStats(this.state.company())
                .then((software) => {
                    this.softwareNames = software.map((x) => x.name);
                });

            this.qualysVesselsApiClient.getQualysVessels(
                this.state.company(),       // company
                undefined,                  // query
                undefined,                  // fromHighestRisk
                undefined,                  // toHighestRisk
                undefined,                  // fromAverageRisk
                undefined,                  // toAverageRisk
                'SiteName',                 // sortField
                'asc',                      // sortDirection
                OrderingType.AlphaNumeric,  // sortType
            ).then((vesselsPagedList) => {
                this.vessels = vesselsPagedList.items;
            });
        }, 500);
    }

    private async retrieveStats(): Promise<void> {
        const assetsStats = await this.qualysAssetsApiClient.getQualysAssetsStats(this.state.company());
        this.affectedAssets = assetsStats?.affectedAssets?.length;
        this.totalAssets = assetsStats?.totalAssets;
        this.highestRisk = assetsStats?.highestRisk;
        this.averageRisk = assetsStats?.averageRisk ? Math.round(assetsStats.averageRisk) : undefined;

        if (assetsStats) {
            // Set dynamic options, such as title + subtitle
            // Add subtitle if it's defined
            this.options = {
                ...this.options,
                subtitle: {
                    text: 'Operating<br/>Systems',
                    verticalAlign: 'middle',
                    floating: true,
                    style: {
                        color: ThemeColors.DarkerGrey,
                        fontSize: '12pt'
                    },
                    y: 15
                },
            };
            this.series = this.mapSeries(assetsStats);
        } else {
            this.series = [];
        }
    }

    private async search(searchValue: string): Promise<void> {
        this.query = searchValue;
        this.resetAssets();
    }

    private async resetAssets(): Promise<void> {
        this.assets = [];
        this.total = undefined;
        this.skip = 0;
        await this.fetchAssets(0, true, false);
    }

    private async fetchAssets(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // Do not attempt to fetch more when all have been retrieved
        if (this.assets.length === this.total) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left
        // to fetch
        this.assetsLoading = true;
        const results = await this.qualysAssetsApiClient.getQualysAssets(
            this.state.company(),          // company
            this.skip,                     // skip
            this.take,                     // take
            this.query,                    // query
            this.filters.siteId,           // site id
            this.filters.riskRange[0],     // fromRisk
            this.filters.riskRange[1],     // toRisk
            this.filters.operatingSystem,  // operating system
            this.filters.software,         // software
            undefined,                     // external references
            this.sortModel.field,          // sortField
            this.sortModel.direction,      // sortDirection
            OrderingType.AlphaNumeric,     // orderingType
        );

        if (!results) {
            this.assetsLoading = false;
            return;
        }

        this.assets = this.assets.concat(results.items);
        this.total = results.total;

        this.skip += this.take;

        this.assetsLoading = false;
    }

    private async sortModelChanged(): Promise<void> {
        await this.search(this.query);
    }

    private getRisk(asset: QualysAsset): number | null {
        if (asset === undefined || asset.truRiskScore === undefined) {
            return null;
        }
        return Math.floor(asset.truRiskScore / 10);
    }

    private filterChanged(): void {
        this.setUrlFilterParams();

        this.search(this.query);
    }

    private riskFilterChange = async (range: number[]): Promise<void> => {
        this.filters.riskRange = range;

        this.setUrlFilterParams();

        await this.search(this.query);
    };

    private clearFilter(filter: FilterNames): void {
        switch (filter) {
            case FilterNames.SiteId:
                this.filters.siteId = this.filterDefaults.siteId;
                break;
            case FilterNames.Risk:
                this.filters.riskRange = this.filterDefaults.riskRange;
                if (this.riskFilterRangeSlider)
                    this.riskFilterRangeSlider.reset();
                break;
            case FilterNames.OperatingSystem:
                this.filters.operatingSystem = this.filterDefaults.operatingSystem;
                break;
            case FilterNames.Software:
                this.filters.software = this.filterDefaults.software;
                break;
        }

        this.search(this.query);

        this.setUrlFilterParams();
    }

    private clearFilters(): void {
        this.filters.siteId = this.filterDefaults.siteId;

        this.filters.riskRange = this.filterDefaults.riskRange;
        if (this.riskFilterRangeSlider)
            this.riskFilterRangeSlider.reset();

        this.filters.operatingSystem = this.filterDefaults.operatingSystem;
        this.filters.software = this.filterDefaults.software;

        this.search(this.query);

        this.setUrlFilterParams();
    }

    @computedFrom('filters.siteId', 'filters.riskRange', 'filters.operatingSystem')
    private get hasActiveFilters(): boolean {
        return this.filters.siteId
            || this.filters.riskRange[0] !== this.filterDefaults.riskRange[0]
            || this.filters.riskRange[1] !== this.filterDefaults.riskRange[1]
            || this.filters.operatingSystem;
    }

    private setUrlFilterParams(): void {
        const route = 'vulnerability-scanner/assets';

        const params = new URLSearchParams();

        if (this.filters.siteId) params.append('site', this.filters.siteId);

        if (this.filters.riskRange[0] !== this.filterDefaults.riskRange[0]
            || this.filters.riskRange[1] !== this.filterDefaults.riskRange[1]) {
            params.append('risk', this.filters.riskRange.toString());
        }

        if (this.filters.operatingSystem) {
            params.append('os', this.filters.operatingSystem);
        }

        if (this.filters.software) {
            params.append('software', this.filters.software);
        }

        UrlUtilities.setUrlParams(this.router, route, params);
    }

    private mapSeries(chartData: QualysAssetStatsResult): Highcharts.SeriesOptionsType[] {
        const colors = ['#00A1DF', '#F07D00', '#E30613', '#9D9D9D'];
        const osData = Object.entries(chartData.osCount);
        return [
            {
                name: 'Assets',
                data: osData.map(([key, value], index) => {
                    return {
                        name: key.charAt(0).toUpperCase() + key.slice(1),
                        y: value,
                        color: colors[index]
                    };
                }),
                point: {
                    events: {
                        click: (event: any): boolean => {
                            // Retrieve the name from the slice name
                            const sliceName: string = event.point.name;

                            this.filters.operatingSystem = sliceName;
                            this.filterChanged();

                            return true;
                        }
                    }
                },
                cursor: 'pointer',
                type: 'pie'
            }
        ];
    }
}
