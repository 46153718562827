import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { StateApi } from '../../../services/state-api';
import {
    GetVulnerabilityResponse,
    OrderingType, QualysAsset, QualysAssetsApiClient,
    QualysVessel,
    QualysVesselsApiClient,
    QualysVulnerabilitiesApiClient
} from '../../../services/cyber-api';
import { ISortModel } from '../components/sort/sort';
import { Router } from 'aurelia-router';
import { MultiRangeSlider } from '../../../components/multi-range-slider/multi-range-slider';
import { FilterNames } from '../models/filter-names';
import { UrlUtilities } from '../../../utilities/url-utilities';

@autoinject()
export class Vulnerabilities {
    private query?: string;
    private vulnerabilities: GetVulnerabilityResponse[] = [];
    private total: number;
    @observable() public sortModel: ISortModel;
    private take: number = 50;
    private skip: number = 0;
    private vulnerabilitiesLoading: boolean = false;
    private FilterNames: typeof FilterNames = FilterNames;
    private riskFilterRangeSlider: MultiRangeSlider;
    private types: string[] = ['Confirmed', 'Potential'];
    private vessels: QualysVessel[] = undefined;
    private assets: QualysAsset[] = undefined;
    private filters = {
        siteId: undefined,
        asset: undefined,
        severity: undefined,
        riskRange: [0, 100],
        type: undefined,
    };
    private filterDefaults = {
        siteId: undefined,
        asset: undefined,
        severity: undefined,
        riskRange: [0, 100],
        type: undefined,
    };
    private confirmedVulnerabilitiesSeries: Highcharts.SeriesColumnOptions[];
    private potentialVulnerabilitiesSeries: Highcharts.SeriesColumnOptions[];
    private columnChartOptions: Highcharts.Options = {
        chart: {
            zooming: {
                type: null
            },
        },
        legend: {
            enabled: false
        },
        xAxis: {
            type: 'category',
            lineWidth: 0,
            labels: {
                formatter() {
                    // Access the corresponding y-value for this x-axis index
                    const series = this.axis.series[0]; // Assumes single series
                    const point = series.data[this.pos]; // Get the data point
                    return point ? point.y.toString() : '';
                }
            }
        },
        yAxis: {
            opposite: true,
            tickInterval: 1,
            gridLineWidth: 0,
            labels: {
                enabled: false
            },
            title: {
                text: ''
            }
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.f}'
                },
            },
            column: {
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.name}'
                },
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    },
                    yAxis: {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -5
                        },
                        title: {
                            text: null
                        }
                    },
                    subtitle: {
                        text: null
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        },
    };

    private totalVulnerabilities: number | undefined = undefined;
    private affectedAssets: number | undefined = undefined;
    private totals: { key: string, value: number, color: string }[] = [];

    private chartColors = [
        '#F7CE46',
        '#F19E38',
        '#ED702D',
        '#EB4826',
        '#E30613'
    ];

    constructor(
        private qualysVulnerabilitiesApiClient: QualysVulnerabilitiesApiClient,
        private qualysVesselsApiClient: QualysVesselsApiClient,
        private qualysAssetsApiClient: QualysAssetsApiClient,
        private state: StateApi,
        private router: Router
    ) {
    }

    private async activate(params: any): Promise<void> {
        this.query = params.query;

        this.setFiltersFromParams(params);

        this.sortModel = {
            direction: 'desc',
            field: 'ExternalReference',
        };

        this.retrieveStats();

        this.retrieveFilters();
    }

    private setFiltersFromParams(params: any): void {
        this.filters.siteId = params.site ? params.site : this.filterDefaults.siteId;
        this.filters.asset = params.asset ? params.asset : this.filterDefaults.asset;
        this.filters.severity = params.severity ? params.severity : this.filterDefaults.severity;
        this.filters.riskRange = params.risk ? params.risk.split(',').map(Number) : this.filterDefaults.riskRange;
        this.filters.type = params.type ? params.type : this.filterDefaults.type;
    }

    private async retrieveStats(): Promise<void> {
        const vulnerabilityStats = await this.qualysVulnerabilitiesApiClient.getQualysVulnerabilityDetectionStatistics(this.state.company());
        if (!vulnerabilityStats) {
            this.confirmedVulnerabilitiesSeries = [];
            this.potentialVulnerabilitiesSeries = [];
            this.totals = [];
            this.affectedAssets = undefined;
            this.totalVulnerabilities = undefined;
            return;
        }
        this.affectedAssets = vulnerabilityStats.hostExternalReferences.length;
        this.totalVulnerabilities = vulnerabilityStats.count;

        // totals should be a sum of confirmedCount and potentialCount
        this.totals = Object.entries(vulnerabilityStats.confirmedCount).map(([key, value], index) => {
            return {
                key,
                value: value + vulnerabilityStats.potentialCount[key],
                color: this.chartColors[index]
            };
        });

        this.confirmedVulnerabilitiesSeries = this.mapConfirmedVulnerabilitiesSeries(vulnerabilityStats.confirmedCount);
        this.potentialVulnerabilitiesSeries = this.mapPotentialVulnerabilitiesSeries(vulnerabilityStats.potentialCount);
    }

    private async retrieveFilters(): Promise<void> {
        // To prioritize initial loading speed, delay retrieval of entities necessary for filters
        setTimeout(() => {
            this.retrieveVessels();
            this.retrieveAssets();
        }, 500);
    }

    private async retrieveVessels(): Promise<void> {
        const vesselsPagedList = await this.qualysVesselsApiClient.getQualysVessels(
            this.state.company(),       // company
            undefined,                  // query
            undefined,                  // fromHighestRisk
            undefined,                  // toHighestRisk
            undefined,                  // fromAverageRisk
            undefined,                  // toAverageRisk
            'SiteName',                 // sortField
            'asc',                      // sortDirection
            OrderingType.AlphaNumeric,  // sortType
        );
        this.vessels = vesselsPagedList.items;
    }

    private async retrieveAssets(): Promise<void> {
        const assetsPagedList = await this.qualysAssetsApiClient.getQualysAssets(
            this.state.company(),       // company
            0,                          // skip
            5000,                       // take
            undefined,                  // query
            undefined,                  // site id
            undefined,                  // fromRisk
            undefined,                  // toRisk
            undefined,                  // operatingSystem
            undefined,                  // software
            undefined,                  // externalReferences
            'Name',                     // sortField
            'desc',                     // sortDirection
            OrderingType.AlphaNumeric,  // orderingType
        );
        // Sort by asset name so that any names that are actually IPs are sorted to the bottom of the list
        assetsPagedList.items.sort((a, b) => {
            const ipRegex = /((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}/;
            const aIsIp = a.name.match(ipRegex);
            const bIsIp = b.name.match(ipRegex);
            if (aIsIp && !bIsIp)
                return 1;
            else if (!aIsIp && bIsIp)
                return -1;
            else
                return a.name.localeCompare(b.name);
        });
        this.assets = assetsPagedList.items;
    }

    private async search(searchValue: string): Promise<void> {
        this.vulnerabilitiesLoading = true;

        this.query = searchValue;
        await this.resetVulnerabilities();

        this.vulnerabilitiesLoading = false;
    }

    private async resetVulnerabilities(): Promise<void> {
        this.vulnerabilities = [];
        this.total = undefined;
        this.skip = 0;
        await this.fetchVulnerabilities(0, true, false);
    }

    private async fetchVulnerabilities(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // Do not attempt to fetch more when all have been retrieved
        if (this.vulnerabilities.length === this.total) return;

        this.vulnerabilitiesLoading = true;
        const vulnerabilitiesPagedList = await this.qualysVulnerabilitiesApiClient.getQualysVulnerabilities(
            this.state.company(),                           // company
            this.skip,                                      // skip
            this.take,                                      // take
            this.query,                                     // query
            this.filters.siteId,                            // site id
            this.filters.asset                              // hostExternalReferences
                ? [this.filters.asset]
                : undefined,
            this.filters.severity,                          // severity
            this.filters.riskRange[0],                      // fromRisk
            this.filters.riskRange[1],                      // toRisk
            this.filters.type,                              // type
            this.sortModel.field,                           // sortField
            this.sortModel.direction,                       // sortDirection
            OrderingType.AlphaNumeric,                      // orderingType
        );

        if (!vulnerabilitiesPagedList) {
            this.vulnerabilitiesLoading = false;
            return;
        }

        this.vulnerabilities = this.vulnerabilities.concat(vulnerabilitiesPagedList.items);
        this.total = vulnerabilitiesPagedList.total;

        this.skip += this.take;

        this.vulnerabilitiesLoading = false;
    }

    private async sortModelChanged(): Promise<void> {
        await this.search(this.query);
    }

    private filterChanged(): void {
        this.setUrlFilterParams();

        this.search(this.query);
    }

    private severityChanged(severity: number): void {
        if (this.filters.severity === severity)
            severity = undefined;

        this.filters.severity = severity;

        this.setUrlFilterParams();

        this.search(this.query);
    }

    private riskFilterChange = async (range: number[]): Promise<void> => {
        this.filters.riskRange = range;

        this.setUrlFilterParams();

        await this.search(this.query);
    };

    private clearFilter(filter: FilterNames): void {
        switch (filter) {
            case FilterNames.SiteId:
                this.filters.siteId = this.filterDefaults.siteId;
                break;
            case FilterNames.Asset:
                this.filters.asset = this.filterDefaults.asset;
                break;
            case FilterNames.Severity:
                this.filters.severity = this.filterDefaults.severity;
                break;
            case FilterNames.Risk:
                this.filters.riskRange = this.filterDefaults.riskRange;
                if (this.riskFilterRangeSlider)
                    this.riskFilterRangeSlider.reset();
                break;
            case FilterNames.VulnerabilityType:
                this.filters.type = this.filterDefaults.type;
                break;
        }

        this.search(this.query);

        this.setUrlFilterParams();
    }

    private clearFilters(): void {
        this.filters.siteId = this.filterDefaults.siteId;
        this.filters.asset = this.filterDefaults.asset;
        this.filters.severity = this.filterDefaults.severity;

        this.filters.riskRange = this.filterDefaults.riskRange;
        if (this.riskFilterRangeSlider)
            this.riskFilterRangeSlider.reset();

        this.filters.type = this.filterDefaults.type;

        this.search(this.query);

        this.setUrlFilterParams();
    }

    @computedFrom('filters.siteId', 'filters.asset', 'filters.severity', 'filters.riskRange', 'filters.type')
    private get hasActiveFilters(): boolean {
        return this.filters.siteId
            || this.filters.asset
            || this.filters.severity
            || this.filters.riskRange[0] !== this.filterDefaults.riskRange[0]
            || this.filters.riskRange[1] !== this.filterDefaults.riskRange[1]
            || this.filters.type;
    }

    private setUrlFilterParams(): void {
        const route = 'vulnerability-scanner/vulnerabilities';

        const params = new URLSearchParams();

        if (this.filters.siteId)
            params.append('site', this.filters.siteId);

        if (this.filters.asset)
            params.append('asset', this.filters.asset);

        if (this.filters.severity)
            params.append('severity', this.filters.severity);

        if (this.filters.riskRange[0] !== this.filterDefaults.riskRange[0]
            || this.filters.riskRange[1] !== this.filterDefaults.riskRange[1]) {
            params.append('risk', this.filters.riskRange.toString());
        }

        if (this.filters.type)
            params.append('type', this.filters.type);

        UrlUtilities.setUrlParams(this.router, route, params);
    }

    private mapConfirmedVulnerabilitiesSeries(data: { [key: string]: number; }): Highcharts.SeriesColumnOptions[] {
        const pointWidth = 76;
        const series = Object.entries(data);
        return [
            {
                name: 'crit',
                data:
                    series.map(([key, value], index) => {
                        return {
                            color: this.chartColors[index],
                            name: 'Severity ' + key,
                            y: value,
                            pointWidth
                        };
                    }),
                point: {
                    events: {
                        click: (event: any): boolean => {
                            // Retrieve the severity from the slice name
                            const sliceName: string = event.point.name.toLowerCase();
                            this.filters.severity = sliceName.split(' ')[1];
                            this.filters.type = 'Confirmed';
                            this.filterChanged();

                            return true;
                        }
                    }
                },
                cursor: 'pointer',
                type: 'column'
            }
        ];
    }

    private mapPotentialVulnerabilitiesSeries(data: { [key: string]: number; }): Highcharts.SeriesColumnOptions[] {
        const pointWidth = 76;
        const series = Object.entries(data);
        return [
            {
                name: 'crit',
                data:
                    series.map(([key, value], index) => {
                        return {
                            color: this.chartColors[index],
                            name: 'Severity ' + key,
                            y: value,
                            pointWidth
                        };
                    }),
                point: {
                    events: {
                        click: (event: any): boolean => {
                            // Retrieve the severity from the slice name
                            const sliceName: string = event.point.name.toLowerCase();
                            this.filters.severity = sliceName.split(' ')[1];
                            this.filters.type = 'Potential';
                            this.filterChanged();

                            return true;
                        }
                    }
                },
                cursor: 'pointer',
                type: 'column'
            }
        ];
    }
}
