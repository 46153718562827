export enum FilterNames {
    AverageAssetRisk = 'AverageAssetRisk',
    HighestAssetRisk = 'HighestAssetRisk',
    SiteId = 'SiteId',
    Risk = 'Risk',
    OperatingSystem = 'OperatingSystem',
    Software = 'Software',
    Severity = 'Severity',
    VulnerabilityType = 'VulnerabilityType',
    Asset = 'Asset',
}
