import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, containerless, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventKeys } from 'enums/event-keys';
import {
    EnrichedNotification,
    EventTypes,
    NotificationsApiClient,
    NotificationStatus,
    SortableNotificationsFields
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import Swal from 'sweetalert2';
import { Toast } from 'utilities/toast';

@autoinject()
@containerless()
export class NotificationList {
    private unreadCount: number;
    private notifications: EnrichedNotification[] = [];
    private readonly take: number = 50;
    private skip: number = 0;
    private loadingNotifications: boolean = true;
    private loadingCount: boolean = true;
    private loadingMarkAll: boolean = false;
    @observable() private showReadNotifications: boolean = false;
    private EventTypes: typeof EventTypes = EventTypes;
    private totalNotificationsCount: number;

    constructor(
        private notificationsApi: NotificationsApiClient,
        private state: StateApi,
        private router: Router,
        private ea: EventAggregator
    ) {
    }

    private async refresh(): Promise<void> {
        // Clear the skip and notifications collection
        this.skip = 0;
        this.totalNotificationsCount = undefined;
        this.notifications = [];

        await Promise.all([
            // Retrieve the first batch of notifications, after which next 'pages' will be retrieved upon reaching the end
            // of the infinite-scroller
            await this.fetchNotifications(0, true, false),
            // Retrieve the unread notification count
            await this.fetchUnreadCount()
        ]);
    }

    private async fetchNotifications(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // When at the end of the list and no more notifications are available, short-circuit as there's nothing left to fetch
        if (this.totalNotificationsCount === this.notifications.length) return;

        this.loadingNotifications = true;

        const pagedNotifications = await this.notificationsApi.getAll(
            this.state.company(),
            this.take,
            this.skip,
            SortableNotificationsFields.Event_detected_date,
            'desc',
            this.showReadNotifications ? undefined : NotificationStatus.Unread
        );

        if (!pagedNotifications) {
            this.loadingNotifications = false;
            return;
        }

        const notifications = pagedNotifications.items;
        this.totalNotificationsCount = pagedNotifications.total;

        this.notifications = this.notifications.concat(notifications);

        this.skip += this.take;

        this.loadingNotifications = false;
    }

    private async fetchUnreadCount(): Promise<void> {
        this.loadingCount = true;
        this.unreadCount = await this.notificationsApi.count(this.state.company(), NotificationStatus.Unread);
        this.loadingCount = false;
    }

    /**
     * Marks all notifications of the current user as read.
     */
    private async readAll(): Promise<void> {
        Swal.fire({
            title: 'Read all notifications',
            html: `Are you sure you want to mark all your notifications as read?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, mark all as read',
        }).then(async (result) => {
            if (result.value) {
                this.loadingMarkAll = true;

                await this.notificationsApi.readAll(
                    this.state.company()
                ).then(() => {
                    Toast.success('All notifications have been marked as read');
                }).catch((error) =>
                    Toast.warning('Marking all notifications as read was unsuccessful, please try again later')
                );

                this.loadingMarkAll = false;

                await Promise.all([
                    this.refresh(),
                    this.fetchUnreadCount()
                ]);

                // Notify rest of the application that notifications have been read (updates header notification count)
                this.ea.publish(EventKeys.onCyberNotificationsRead);
            }
        });
    }

    /**
     * Navigates to a threat of a notification.
     * Alternative to route-href attribute as this click will bubble upwards to the row click which triggers
     * notificationClicked which in turn reads the notification. Using route-href, it would not be possible to mark the
     * notification as read.
     * @param notification Notification containing the threat that will be navigated to.
     */
    private navigateToThreat(notification: EnrichedNotification): void {
        this.markNotificationAsRead(notification);
        const shortId = notification.attributes.alert_short_id || notification.attributes.short_id;
        this.router.navigateToRoute('threats', { id: shortId });
    }

    /**
     * Navigates to a case of a notification.
     * Alternative to route-href attribute as this click will bubble upwards to the row click which triggers
     * notificationClicked which in turn reads the notification. Using route-href, it would not be possible to mark the
     * notification as read.
     * In addition to the above, cases have to be navigated to using router.navigate as you can't navigate to a child
     * route of a different router, unfortunately, the root/top-level router doesn't know the case details route exists.
     * @param notification Notification containing the casebook that will be navigated to.
     */
    private navigateToCase(notification: EnrichedNotification): void {
        this.markNotificationAsRead(notification);
        this.router.navigate(`/cases/${notification.attributes.uuid}`);
    }

    /**
     * Navigates to a vessel of a notification.
     * Alternative to route-href attribute as this click will bubble upwards to the row click which triggers
     * notificationClicked which in turn reads the notification. Using route-href, it would not be possible to mark the
     * notification as read.
     * In addition to the above, vessels have to be navigated to using router.navigate as you can't navigate to a child
     * route of a different router, unfortunately, the root/top-level router doesn't know the case details route exists.
     * @param notification Notification containing the vessel that will be navigated to.
     */
    private navigateToVessel(notification: EnrichedNotification): void {
        this.markNotificationAsRead(notification);
        this.router.navigate(`/vessels/${notification.attributes.site_id}`);
    }

    /**
     * Marks a notification, if not already read, as read.
     * @param notification Notification to mark as read.
     */
    private async markNotificationAsRead(notification: EnrichedNotification): Promise<void> {
        if (notification.status as any === 'Unread') {
            // update optimistic UI
            notification.status = NotificationStatus.Read;
            // Mark the notification as read
            await this.notificationsApi.read(notification.id, this.state.company())
                .then((readNotification) =>
                    // Overwrite the given notification with the updated notification to update its display in the view
                    Object.assign(notification, readNotification)
                );

            await this.fetchUnreadCount();

            // Notify the rest of the application that a notification has been read (updates header notification count)
            this.ea.publish(EventKeys.onCyberNotificationRead, notification);


        }
    }

    private async showReadNotificationsChanged(newValue: boolean, oldValue: boolean): Promise<void> {
        await this.refresh();
    }
}
