import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration } from 'aurelia-router';
import { inlineView } from 'aurelia-templating';

@inlineView('<template><router-view swap-order="after"></router-view></template>')
export class VesselsIndex {
    private configureRouter(config: RouterConfiguration, router: Router): void {
        config.map([
            { route: '', name: 'vessels', moduleId: PLATFORM.moduleName('./vessels') },
            { route: ':id', href: 'vessel', name: 'vessel', moduleId: PLATFORM.moduleName('./vessel/vessel'), nav: true, title: 'Vessel Details', settings: { icon: 'fal fa-ship', breadcrumb: true } }
        ]);
    }
}
